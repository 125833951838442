import React from 'react';
import agreeModalStyles from './AgreeModal.module.css'

const AgreeModal = ({children, visible, setVisible, setAction}) => {

    const rootClasses = [agreeModalStyles.myModal]

    if(visible){
        rootClasses.push(agreeModalStyles.active)
    }

    const execute = (event) => {
        event.preventDefault()
        setAction(true)
        setVisible(false)
    }

    const hide = (event) => {
        event.preventDefault()
        setVisible(false)
    }

    return (
        <div className={rootClasses.join(' ')}>
            <div className={agreeModalStyles.myModalContent}>
                <div>{children}</div>
                <button onClick={(event) => execute(event)}>Ок</button>
                <button onClick={(event) => hide(event)}>Отменить</button>
            </div>
        </div>
    );
};

export default AgreeModal;
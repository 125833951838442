import React, {useEffect, useState} from 'react';
import PostService from "../API/PostService";
import Loader from "../components/UI/loader/Loader";
import MyModal from "../components/UI/MyModal/MyModal";
import UserTestCard from "../components/UserTestCard";
import MainStyles from "../styles/Main.module.css"
import Styles from "../styles/Test.module.css"

const UserMain = () => {
    const [tests, setTests] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [modal, setModal] = useState(false)
    const [modalText, setModalText] = useState('')
    useEffect(() => {
        async function fetchData() {
            return await PostService.GetUserTests()
        }
        fetchData().then(response => {
            if(response.err===null && response.data.length !==0){
                setTests(response.data)
            }else if(response.err!==null){
                setModalText(response.err)
                setModal(true)
            }
            setIsLoading(false)});
    }, []);

    return (
        isLoading ?
            <Loader/>
            :(
                <div className = {MainStyles.background}>
                    <div className = {MainStyles.BackObj1}></div>
                    <div className = {MainStyles.BackObj2}></div>
                    <div className = {MainStyles.BackObj3}></div>
                    <div className = {MainStyles.BackObj4}></div>
                    <div className = {MainStyles.BackObj5}>
                        <h7>Что-то тут будет</h7>
                    </div>
                    <div className = {MainStyles.Title}>
                        <h8>Какой-то текст</h8>
                        <h9>Съешь еще этих мягких французских булок да выпей чаю или можно еще чаю и еще чаю и еще чаю и еще чаю</h9>
                    </div>



                    <div className = {MainStyles.BackgroundFake}>
                        <MyModal visible={modal} setVisible={setModal}>{modalText}</MyModal>
                        {
                            tests?
                                tests.map(test => (
                                    <UserTestCard name={test.name} description={test.description}/>
                                ))
                                :
                                <div className={MainStyles.NoTestsBackground}>
                                    <h5>No tests, sorry</h5>
                                    <div className={Styles.HumsterImg}>
                                        <img
                                            src="https://yt3.googleusercontent.com/zK4uM8yuM1a-LKLM_Nzrwf_Fhnae4zOIKP4S2bONTkA6ecbCBUqYgnvcHd9VAidHr7DS8A_N=s900-c-k-c0x00ffffff-no-rj"
                                            alt={"sad hamster"}/>
                                    </div>
                                </div>
                        }

                    </div>


                </div>

            )
    );
};

export default UserMain;
import React, {useContext, useEffect, useState} from 'react';
import MyInput from "../components/UI/input/MyInput";
import MyButton from "../components/UI/button/MyButton";
import {AuthContext} from "../context";
import PostService from "../API/PostService";
import Loader from "../components/UI/loader/Loader";
import MyModal from "../components/UI/MyModal/MyModal";
import AdminTestCard from "../components/AdminTestCard";
import Styles from "../styles/AdminMainAndTestCard.module.css";
import AgreeModal from "../components/UI/agreeModal/AgreeModal";
import Styless from "../styles/Test.module.css"

const AdminMain = () => {
    const [tests, setTests] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [modal, setModal] = useState(false)
    const [modalText, setModalText] = useState('')
    const [agree, setAgree] = useState(false);
    const [action, setAction] = useState(false);
    const [testNameForDelete, setTestNameForDelete] = useState('')

    const getData = () =>{
        async function fetchData() {
            return await PostService.GetAdminTests()
        }
        fetchData().then(response => {
            if(response.err===null && response.data.length !==0){
                setTests(response.data)
            }else if(response.err!==null){
                setModalText(response.err)
                setModal(true)
            }
            setIsLoading(false)});
    }


    useEffect(() => {
        getData()
    }, []);


    useEffect(() => {
        if(!action)return;
        setIsLoading(true)
        async function fetchData() {
            console.log(testNameForDelete)
            return await PostService.DeleteTest(testNameForDelete)
        }
        fetchData().then(response => {
            setModalText(response.err === null ? 'Тест удален успешно' : response.err)
            setModal(true)
            setAction(false)
            getData()
        });
    }, [action]);

    return (
        isLoading ?
            <Loader/>
            :(
                <div className={Styles.background}>
                    {
                        tests?
                            tests.map(test => (
                                <AdminTestCard
                                    setNameForDelete={setTestNameForDelete}
                                    setAgreeVisible={setAgree}
                                    key={test.name}
                                    name={test.name}
                                    amountOfAnswers={test.answers_quantity}
                                />
                            ))
                            :
                            <div className={Styles.NoTestsBackground}>

                                <h5>No tests, sorry</h5>
                                <div className={Styless.HumsterImg}>
                                    <img
                                        src="https://yt3.googleusercontent.com/zK4uM8yuM1a-LKLM_Nzrwf_Fhnae4zOIKP4S2bONTkA6ecbCBUqYgnvcHd9VAidHr7DS8A_N=s900-c-k-c0x00ffffff-no-rj"></img>
                                </div>
                            </div>
                    }
                    <MyModal visible={modal} setVisible={setModal}>{modalText}</MyModal>
                    <AgreeModal
                        visible={agree}
                        setVisible={setAgree}
                        setAction={setAction}
                    >
                        При удалении теста, он будет полностью и безвозвратно удален, вместе со всеми ответами.
                        Если вы согласы, нажмите ОК
                    </AgreeModal>
                </div>
            )
    );
};

export default AdminMain;
import React, {useEffect, useRef, useState} from 'react';
import Styles from "../styles/Test.module.css"

const TestComponent = ({time, isNewBlock, setIsNewBlock, instructionPointer, answers, setAnswers,
                           instructions, setInstructionPointer, maxLenght, allImages, setHasEnded}) => {

    const instructionPointerRef = useRef(instructionPointer);

    const [isWrong, setIsWrong] = useState(false)
    const [isRight, setIsRight] = useState(false)

    useEffect(() => {
        time.current = Date.now()
    }, []);

    useEffect(() => {
        instructionPointerRef.current = instructionPointer;
    }, [instructionPointer]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (isWrong || isRight || isNewBlock) return; // Проверяем, нет ли блокировки

            const isLeftKey = (event.key === 'a' || event.key === 'A' || event.key === 'ф' || event.key === 'Ф');
            const isRightKey = (event.key === 'l' || event.key === 'L' || event.key === 'д' || event.key === 'Д');

            if (isLeftKey || isRightKey) {
                const correctSide = instructions[instructionPointerRef.current].right_side === (isLeftKey ? 'left' : 'right');

                if (!correctSide) {
                    setIsWrong(true);
                    setTimeout(() => setIsWrong(false), 248);
                } else {
                    setIsRight(true);
                    const tmp = [...answers];
                    tmp[instructionPointerRef.current] = {
                        block: instructions[instructionPointerRef.current].block,
                        time: Date.now() - time.current,
                    };
                    setAnswers(tmp);

                    setTimeout(() => {
                        setIsRight(false)
                        }, 248);

                    const nextInstructionPointer = instructionPointerRef.current + 1;

                    if (nextInstructionPointer < instructions.length && instructions[instructionPointerRef.current].block !== instructions[nextInstructionPointer].block) {
                        setIsNewBlock(true);
                        setInstructionPointer(prevPointer => prevPointer + 1);
                        setTimeout(() => {
                            setIsNewBlock(false);
                            time.current = Date.now();
                        }, 5000);
                    } else if (nextInstructionPointer < maxLenght) {
                        setInstructionPointer(prevPointer => prevPointer + 1);
                        time.current = Date.now();
                    } else {
                        setHasEnded(true);
                    }
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [maxLenght, isWrong, isRight, isNewBlock]);


    if (isWrong) {
        return (
            <div className={Styles.BackTest}>
                <h1>Неправильно</h1>
            </div>
        )
    }

    if (isRight) {
        return (
            <div className={Styles.BackTest}>
                <h2>Правильно</h2>
            </div>
        )
    }

    if (isNewBlock) {
        return (
            <div className={Styles.BackTest}>

                    <div className={Styles.BackLeftRight}>
                        <div className={Styles.Left}>
                            {instructions[instructionPointer].left1}
                        </div>

                        <div className={Styles.Left}>
                            {instructions[instructionPointer].left2}
                        </div>
                    </div>

                    <h2>новый набор стимулов</h2>

                    <div className={Styles.BackLeftRight}>
                        <div className={Styles.Right}>
                            {instructions[instructionPointer].right1}
                        </div>

                        <div className={Styles.Right}>
                            {instructions[instructionPointer].right2}
                        </div>
                    </div>
                </div>

        )
    }

    return (
        <div className={Styles.BackTest}>
            <div className={Styles.BackLeftRight}>
                <div className={Styles.Left}>
                    {instructions[instructionPointer].left1}
                </div>

                <div className={Styles.Left}>
                {instructions[instructionPointer].left2}
                </div>
            </div>

            <div className={Styles.ImgTest}>
                <img src={`data: ;base64,${allImages[instructions[instructionPointer].attribute_index].image[instructions[instructionPointer].array_index]}`}/>
            </div>

            <div className={Styles.BackLeftRight}>
                <div className={Styles.Right}>
                    {instructions[instructionPointer].right1}
                </div>

                <div className={Styles.Right}>
                    {instructions[instructionPointer].right2}
                </div>
            </div>
        </div>
    );
};

export default TestComponent;
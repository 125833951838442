import React from 'react';
import descriptionStyles from './DescriptionInput.module.css'

const DescriptionInput = ({visible, setVisible, setInputText}) => {
    const rootClasses = [descriptionStyles.Description]

    if(visible){
        rootClasses.push(descriptionStyles.active)
    }

    const hide = (e) =>{
        e.preventDefault()
        setVisible(false)
    }

    return (
        <div className={rootClasses.join(' ')}>
            <div>
                <div className={descriptionStyles.InputContent}>
                    <textarea
                        className={descriptionStyles.DescriptionInput}
                        onChange={(e) => setInputText(e.target.value)}
                    />
                    <button
                        className={descriptionStyles.button}
                        onClick={(e) => hide(e)}
                    >
                        Сохранить
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DescriptionInput;
import React, {useState} from 'react';
import Styles from "../styles/AdminMainAndTestCard.module.css";
import PostService from "../API/PostService";
import MyModal from "./UI/MyModal/MyModal";

const AdminTestCard = ({setNameForDelete, setAgreeVisible, name, amountOfAnswers}) => {

    const [modal, setModal] = useState(false)
    const [modalText, setModalText] = useState('')

    const downloadFile = () =>{
        async function fetchData() {
            return await PostService.GetExcel(name)
        }
        fetchData().then(response => {
            if (response.err !== null) {
                setModalText(response.err)
                setModal(true)
            }
        });
    }

    const activateAgree = (event) => {
        event.preventDefault()
        setNameForDelete(name)
        setAgreeVisible(true)
    }

    return (

        <div className = {Styles.BackgroundAnswers}>
            <MyModal visible={modal} setVisible={setModal}>{modalText}</MyModal>
            <button
                className={Styles.CardButtonsAnswers}
                onClick={downloadFile}
            >
                Скачать ответы
            </button>

            <div className = {Styles.NameAmount}>
                {name}
            </div>

            <div className = {Styles.NameAmount}>
                {amountOfAnswers}
            </div>

            <button
                className={Styles.CardButtonsDelete}
                onClick={(event) => activateAgree(event)}
            >
                Удалить
            </button>
        </div>
    );
};

export default AdminTestCard;
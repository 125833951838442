import React, {useContext} from 'react';
import navbarStyle from './navbar.module.css';
import { privateRoutes, publicRoutes } from "../../../router";
import { useNavigate } from "react-router-dom";
import {AuthContext} from "../../../context";

const Navbar = () => {
    const {setIsAuth} = useContext(AuthContext)
    const navigate = useNavigate();

    const redirect = (path) => {
        navigate(path);
    }

    const logout = () =>{
        setIsAuth(false)
        localStorage.removeItem("login")
        localStorage.removeItem("password")
        navigate("")
    }

    return (
        <div className={navbarStyle.navbar}>
            {
                privateRoutes.filter(route => route.name !== null)
                    .map(route => (
                        <button className={navbarStyle.NavButtons}
                                key={route.path} id={route.path} onClick={() => redirect(route.path)}>
                            {route.name}
                        </button>
                    ))
            }
            {
                publicRoutes.filter(route => route.name !== null)
                    .map(route => (
                        <button className={navbarStyle.NavButtons}
                                key={route.path} id={route.path} onClick={() => redirect(route.path)}>
                            {route.name}
                        </button>
                    ))
            }
            <button className={navbarStyle.NavButtons} onClick={logout}>Выйти</button>
        </div>
    );
};

export default Navbar;

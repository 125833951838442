import {useState} from "react";
import TableRow from "./TableRow";
import dynamicTableStyles from '../styles/TestAndCreation.module.css'

const DynamicTable = ({rows, setRows, attributes, stimulus}) => {

    const handleRowChange = (index, newRowData) => {
        const newRows = [...rows];
        newRows[index] = newRowData;
        setRows(newRows);
    };

    const addRow = () => {
        setRows([...rows, {
            block: rows.length+1,
            type: 'practice',
            leftOption1: '',
            leftOption2: '',
            rightOption1: '',
            rightOption2: '',
            stimulusCount: 0
        }]);
    };

    return (
        <div>
            <table>
                <thead>
                <tr>
                    <th>Блок</th>
                    <th>Тип</th>
                    <th>Ответы для левой стороны</th>
                    <th>Ответы для правой стороны</th>
                    <th>Количество стимулов</th>
                </tr>
                </thead>
                <tbody>
                {
                    rows.map((row, index) => (
                    <TableRow
                        key={index}
                        rowIndex={index}
                        rowData={row}
                        handleRowChange={handleRowChange}
                        attributes={attributes}
                        stimulus = {stimulus}
                    />
                ))}
                </tbody>
            </table>
            <button className={dynamicTableStyles.buttonCreate} onClick={addRow}>+</button>
        </div>
    );
};

export default DynamicTable;
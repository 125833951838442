import React, {useEffect, useState} from 'react';
import './styles/App.css';
import {BrowserRouter} from "react-router-dom";
import AppRouter from "./components/AppRouter";
import {AuthContext} from "./context";
import PostService from "./API/PostService";
import Loader from "./components/UI/loader/Loader";
import Navbar from "./components/UI/navbar/Navbar";

function App() {
    const [isLoading, setIsLoading] = useState(true)
    const [isAuth, setIsAuth] = useState(false)
    useEffect(() => {
        async function fetchData() {
            return await PostService.checkAuth()
        }
        fetchData().then(response => {
            if(!response.err){
            setIsAuth(response.setAuth)
            }
            setIsLoading(false)});
    }, []);

    return (
        isLoading ?
                <Loader/>
        :
            <AuthContext.Provider value={{
                isAuth,
                setIsAuth
            }}>
                <BrowserRouter>
                    {isAuth?<Navbar/> : null}
                    <AppRouter/>
                </BrowserRouter>
            </AuthContext.Provider>
    )
}

export default App;
import React, {useContext, useState} from 'react';
import MyInput from "../components/UI/input/MyInput";
import MyButton from "../components/UI/button/MyButton";
import {AuthContext} from "../context";
import {Link, Navigate, useNavigate} from "react-router-dom";
import PostService from "../API/PostService";
import MyModal from "../components/UI/MyModal/MyModal";
import loginStyles from '../styles/Login.module.css'

const Login = () => {
    const {isAuth, setIsAuth} = useContext(AuthContext)
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const [modal, setModal] = useState(false)
    const [modalText, setModalText] = useState('')


    const handleLoginChange = (event) => {
        setLogin(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };


    const loginEvent = async event => {
        event.preventDefault()
        setIsLoading(true)
        localStorage.setItem("login", login)
        localStorage.setItem("password", password)
        const response = await PostService.checkAuth()
        setIsAuth(response.setAuth)
        setIsLoading(false)
        if(response.err===null){
            navigate("/admin/create")
        }
        else{
            setModalText(response.err)
            setModal(true)
        }


    }

    return (
        <div className={loginStyles.Form}>
            <div className={loginStyles.background}>
                <form onSubmit={loginEvent}>
                    <h1>Login</h1>
                    <MyInput
                        type="text"
                        placeholder="Введите логин"
                        value={login}
                        onChange={handleLoginChange}
                    />
                    <MyInput
                        type="password"
                        placeholder="Введите пароль"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                    <MyButton>Войти</MyButton>
                </form>
                <MyModal
                    visible={modal}
                    setVisible={setModal}
                >
                    {modalText}
                </MyModal>
            </div>
        </div>
    );
};

export default Login;
import React, {useContext} from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import {privateRoutes, publicRoutes} from "../router";
import {AuthContext} from "../context";

const AppRouter = () => {
    const {isAuth} = useContext(AuthContext)

    return (
        <Routes>
            {
                isAuth && privateRoutes.map(route => (
                    <Route
                        element={route.component}
                        path={route.path}
                        key={route.path}
                    />
                    )
                )}
            {
                publicRoutes.map(route => (
                    <Route
                        element={route.component}
                        path={route.path}
                        key={route.path}
                    />
                    )
                )}
            {/* Редиректы на случай, если путь не найден */}
            <Route path="*" element={<Navigate to={isAuth ? "/admin" : "/"}/>} />
        </Routes>
    );
};

export default AppRouter;
import React from 'react';
import {useNavigate} from "react-router-dom";
import Styles from "../styles/Main.module.css";

const UserTestCard = ({name, description}) => {

    const navigate = useNavigate();

    const goToTest = (event) =>{
        event.preventDefault()
        navigate('/test', {state: name})
    }

    return (
        <div className={Styles.BackgroundAnswers}>

            <div className={Styles.CardName}>
                {name}
            </div>
            <div className={Styles.Description}>
                {description}
            </div>

            <button className={Styles.CardButton} onClick={goToTest}>
                Пройти
            </button>
        </div>
    );
};

export default UserTestCard;
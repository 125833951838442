import Login from "../pages/Login";
import AdminMain from "../pages/AdminMain";
import AdminCreate from "../pages/AdminCreate";
import App from "../App";
import UserMain from "../pages/UserMain";
import Test from "../pages/Test";


export const privateRoutes = [
    {path: '/admin/create', component: <AdminCreate/>, name: "Создать тест"},
    {path: '/admin', component: <AdminMain/>, name: "Главная"}
]

export const publicRoutes = [
    {path: '/admin/login', component: <Login/>, name:null},
    {path: '/test', component: <Test/>, name: null},
    {path: '', component: <UserMain/>, name: "Главная пользовательская"}
]
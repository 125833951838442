const CreateFormData = (personal, answers, name) => {
    const formData = new FormData();

    formData.append('testName', name)
    formData.append('degree', personal.degree)
    formData.append('age', personal.age)
    formData.append('gender', personal.gender)
    answers.forEach((ans, index) =>{
        formData.append(`answer[${index}].block`, ans.block)
        formData.append(`answer[${index}].time`, ans.time)
    })
    return formData
}

export default CreateFormData
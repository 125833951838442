import React from 'react';

const CreateFormData = (setModalText, setModalVisible, testName, attributes, stimulus, rows, description) => {
        let dataNotFull = false
        const formData = new FormData();

        // Добавление имени теста
        if(testName.name === ''){
            setModalText('Вы забыли указать название теста')
            setModalVisible(true)
            dataNotFull = true
        }
        else{
            formData.append('testName', testName.name)
        }

        if(!dataNotFull){
            if(description !== ''){
                formData.append('description', description)
            }
            else{
                setModalText('Вы забыли указать описание теста')
                setModalVisible(true)
                dataNotFull = true
            }
        }

        // Добавление атрибутов
        !dataNotFull && attributes.forEach((attribute, index) => {
            if(attribute.attr === ''){
                setModalText('Вы забыли указать все атрибуты')
                setModalVisible(true)
                dataNotFull = true            }
            else{
                formData.append(`attributes[${index}].attr`, attribute.attr);
            }
        });

        // Добавление стимулов (изображений)
        !dataNotFull && stimulus.forEach((stim, index) => {
            formData.append(`stimulus[${index}].attr`, stim.attr);
            if(stim.image.length === 0){
                setModalText(`Вы забыли добавить изображения для атрибута ${index+1}`)
                setModalVisible(true)
                dataNotFull = true
                return
            }
            stim.image.forEach((file, fileIndex) => {
                formData.append(`stimulus[${index}].image[${fileIndex}]`, file);
            });
        });


        // Добавление строк
        !dataNotFull && rows.forEach((row, index) => {
            formData.append(`rows[${index}].block`, row.block);
            formData.append(`rows[${index}].type`, row.type);
            if (row.leftOption1 === ''){
                setModalText(`Вы забыли добавить хотя бы один ответ для левой стороны в блоке ${index+1}`)
                setModalVisible(true)
                dataNotFull = true
                return
            }
            else{
                formData.append(`rows[${index}].leftOption1`, row.leftOption1);
            }
            if (row.rightOption2 !== '' && row.leftOption2 === ''){
                setModalText(`Вы забыли указать второй ответ для левой стороны в блоке ${index+1}`)
                setModalVisible(true)
                dataNotFull = true
                return
            }
            if(row.leftOption2 !== ''){formData.append(`rows[${index}].leftOption2`, row.leftOption2);}
            if (row.rightOption1 === ''){
                setModalText(`Вы забыли добавить хотя бы один ответ для правой стороны в блоке ${index+1}`)
                setModalVisible(true)
                dataNotFull = true
                return
            }
            else{
                formData.append(`rows[${index}].rightOption1`, row.rightOption1);
            }
            if (row.rightOption2 === '' && row.leftOption2 !== ''){
                setModalText(`Вы забыли указать второй ответ для правой стороны в блоке ${index+1}`)
                setModalVisible(true)
                dataNotFull = true
                return
            }
            if (row.rightOption2 !== ''){formData.append(`rows[${index}].rightOption2`, row.rightOption2);}
            formData.append(`rows[${index}].stimulusCount`, row.stimulusCount);
        });

        return (dataNotFull ? false : formData)
};

export default CreateFormData;
import axios from "axios"

export default class PostService{

    static async DeleteTest(tesName) {
        let funcResponse = {err: null}
        const token = btoa(`${localStorage.getItem("login")}:${localStorage.getItem("password")}`)
        try {
            const response = await axios.delete(`https://veritasinmedio.ru/api/admin/test/${tesName}`,
                {
                    headers: {
                        'Authorization': `Basic ${token}`
                    }
                })
            if (response.status === 200) {
                return funcResponse
            } else {
                funcResponse.err = 'Failed to send request to the server'
                return funcResponse
            }
        } catch (error) {
            funcResponse.err = 'Не получилось удалить тест. Попробуйте перезагрузить страницу'
            return  funcResponse
        }
    }

    static async GetExcel(test) {
        let funcResponse = {err: null}
        const token = btoa(`${localStorage.getItem("login")}:${localStorage.getItem("password")}`)
        try {
            const response = await axios.get(`https://veritasinmedio.ru/api/admin/generate-excel/${test}`, {
                responseType: 'blob',
                headers: {
                    'Authorization': `Basic ${token}`
                }
            });

            if (response.status === 200) {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${test}.xlsx`); //or any other extension
                document.body.appendChild(link);
                link.click();
                link.remove();
                return funcResponse
            } else {
                funcResponse.err = 'Failed to send request to the server'
                return funcResponse
            }
        } catch (error) {
            funcResponse.err = 'Не получилось получить данные. Пожалуйста, перезагрузите страницу и попробуйте заново'
            return  funcResponse
        }
    }


    static async SendAnswers(data) {
        let funcResponse = {err: null}
        try {
            const response = await axios.post(`https://veritasinmedio.ru/api/user/test/answer`, data, {})
            if (response.status === 200) {
                return funcResponse
            } else {
                funcResponse.err = 'Failed to send request to the server'
                return funcResponse
            }
        } catch (error) {
            funcResponse.err = 'Не получилось сохранить данные. Пожалуйста, перезагрузите страницу и пройдите тест заново'
            return  funcResponse
        }
    }

    static async GetUserTestData(test){
        let funcResponse = {err: null, data: null}
        try {
            const response = await axios.get(`https://veritasinmedio.ru/api/user/test/${test}`)
            if (response.status === 200) {
                funcResponse.data = response.data
                return funcResponse
            } else {
                funcResponse.err = 'Failed to send request to the server'
                return funcResponse
            }
        } catch (error) {
            funcResponse.err = 'Failed to get data from the server'
            return  funcResponse
        }
    }

    static async GetUserTests(){
        let funcResponse = {err: null, data: []}
        try {
            const response = await axios.get('https://veritasinmedio.ru/api/user/tests')
            if (response.status === 200) {
                funcResponse.data = response.data
                return funcResponse
            } else {
                funcResponse.err = 'Failed to send request to the server'
                return funcResponse
            }
        } catch (error) {
            funcResponse.err = 'Failed to get data from the server'
            return  funcResponse
        }
    }

    static async GetAdminTests(){
        let funcResponse = {err: null, data: []}
        const token = btoa(`${localStorage.getItem("login")}:${localStorage.getItem("password")}`)
        try {
            const response = await axios.get('https://veritasinmedio.ru/api/admin/tests', {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            })
            if (response.status === 200) {
                funcResponse.data = response.data
                return funcResponse
            } else {
                funcResponse.err = 'Failed to send request to the server'
                return funcResponse
            }
        } catch (error) {
            funcResponse.err = 'Failed to get data from the server'
            return  funcResponse
        }
    }

    static async SendImageTestData(formData){
        let funcResponse = {err: null}
        const token = btoa(`${localStorage.getItem("login")}:${localStorage.getItem("password")}`)
        try {
            const response = await axios.post('https://veritasinmedio.ru/api/admin/SendImageTestData', formData, {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            })
            if (response.status === 200) {
                return funcResponse
            } else {funcResponse.err = 'Failed to send data to the server'}
        } catch (error) {
            try{
                if (error.response.status === 500) {
                    funcResponse.err = "Something went wrong at the server side. Try to refresh the page"
                    return funcResponse
                }else if(error.response.setAuth === 507){
                    funcResponse.err = "Some data was probably damaged. Try to refresh the page"
                    return funcResponse
                }
            } catch (error){
                funcResponse.err = "Unexpected error happened. Try to refresh the page"
                return funcResponse
            }
        }
    }

    static async checkAuth(){
        let funcResponse = {err: null, setAuth: false}
        const token = btoa(`${localStorage.getItem("login")}:${localStorage.getItem("password")}`)
        try {
            const response = await axios.get('https://veritasinmedio.ru/api/checkAuth', {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            })
            if (response.status === 200) {
                funcResponse.setAuth = true;
                return funcResponse
            }
            else{
                funcResponse.err = "Something went wrong on server"
                return funcResponse
            }
        } catch (error) {
            try{
                if (error.response.status === 403) {
                    funcResponse.err = "Bad login or/and password"
                    return funcResponse
                }
            }
            catch (er) {
                funcResponse.err = "Unexpected error happened. Try to refresh the page"
                return funcResponse
            }
        }
    }
}